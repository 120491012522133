import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, CardMedia, Chip, Button } from '@mui/material';
import { FavoriteVideo, FavoriteVideos } from '../../../api/users.interface';

interface ComponentProps {
    favoriteVideos: FavoriteVideos;
}

type Props = ComponentProps;

class FavoriteVideosComponent extends Component<Props> {
    formatDate(timestamp?: number): string {
        if (!timestamp) return 'Unknown';
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderVideo(key: string, video: FavoriteVideo) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={video.videoName}
                        subheader={`Uploaded at: ${this.formatDate(video.uploadDate)}`}
                    />
                    <CardMedia
                        component="img"
                        height="140"
                        image={video.thumbnailUrl || video.thumbnailImageUrl}
                        alt={video.videoName}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            {video.videoDescription}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Duration: {video.duration} minutes
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Level: {video.level}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Category: {video.categoryName || video.category}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Subcategory: {video.subcategory}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {video.isFree ? "Free" : "Paid"}
                        </Typography>
                        {/* <Button 
                            variant="contained" 
                            color="primary" 
                            href={video.videoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Watch Now
                        </Button> */}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { favoriteVideos } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(favoriteVideos).map(([key, video]) => this.renderVideo(key, video))}
                </Grid>
            </Box>
        );
    }
}

export default FavoriteVideosComponent;

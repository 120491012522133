import { AdminDetails } from "../api/auth.types";
import { AUTHENTICATING, AUTH_UPDATE, USER_UPDATE } from "../actions/types";

export interface AuthState {
    user?: AdminDetails;
    loading?: boolean;
    initialized: boolean;
    authenticated: boolean;
    authToken: string;
}

const InitialAuthState: AuthState = {
    user: undefined,
    loading: false,
    initialized: false,
    authenticated: false,
    authToken: '',
};

export const AuthReducer = (state: AuthState = InitialAuthState, action: any) => {
    switch (action.type) {
        case AUTHENTICATING:
            return {
                ...state,
                loading: action.payload,
            };
        case AUTH_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        case USER_UPDATE:
            return {
                ...state,
                user: action.payload,
            };
    }
    return state;
}
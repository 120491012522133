import React, { FunctionComponent, ReactElement, useEffect } from "react";

import { useNavigate } from "react-router";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";

const RequiresAuthenticationComponent: FunctionComponent<{ children: ReactElement<any, any>, loading: boolean, initialized: boolean, authenticated: boolean }> = ({ children, loading, initialized, authenticated }) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (loading || !initialized) {
            return
        }

        if (!authenticated) {
            navigate("/login");
        }
    }, [loading, initialized, authenticated])
    

    useEffect(() => {
        if(loading || !initialized || !authenticated) {
            return;
        }
    }, [loading, initialized, authenticated])

    if(loading || !initialized) {
        return null;
    }

    if (!authenticated) {
        return null;
    }
  
    return <>{children}</>
}

const mapStateToProps = ({ auth }: ReduxState) => {
    const { loading, initialized, authenticated } = auth;

    return { loading, initialized, authenticated };
}

export const RequiresAuthentication = connect(mapStateToProps)(RequiresAuthenticationComponent as any);
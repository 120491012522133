import { GetStateFunction, DispatchFunction } from "../reducers";
import { processProgram as processProgramAPI } from "../api/workouts";
import { addError, addSuccess } from "./notifications";
import { PROCESSING_PROGRAM } from "./types";
import { WorkoutLevel } from "../api/workouts.interface";


export const processProgram = (level: WorkoutLevel)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: PROCESSING_PROGRAM, payload: true });
        await processProgramAPI(level);
        dispatch(addSuccess('Program processed successfully.'));

        dispatch({ type: PROCESSING_PROGRAM, payload: false });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while processing the selected program.'));
        }
        
        dispatch({ type: PROCESSING_PROGRAM, payload: false });
    }
}

import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, CardMedia, Chip } from '@mui/material';
import { PushNotification, PushNotifications } from '../../../api/users.interface';

interface ComponentProps {
    pushNotifications: PushNotifications;
}

type Props = ComponentProps;

class PushNotificationsComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }

    renderNotification(key: string, notification: PushNotification) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={notification.title}
                        subheader={`Sent at: ${this.formatDate(notification.sentTime)}`}
                    />
                    {notification.mediaUrl && (
                        <CardMedia
                            component="img"
                            height="140"
                            image={notification.mediaUrl}
                            alt={notification.title}
                        />
                    )}
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            {notification.message}
                        </Typography>
                        {notification.read && <Chip label="Read" color="primary" />}
                        {!notification.read && <Chip label="Unread" color="secondary" />}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { pushNotifications } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(pushNotifications).map(([key, notification]) => this.renderNotification(key, notification))}
                </Grid>
            </Box>
        );
    }
}

export default PushNotificationsComponent;

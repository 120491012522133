import * as React from 'react';
import { Box, Typography, Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField, SxProps } from '@mui/material';
import { WorkoutStreak } from '../../../api/users.interface';

interface UpdateWorkoutStreakDialogProps {
    open: boolean;
    workoutStreak: WorkoutStreak;
    onClose: () => void;
    onConfirm: (updatedStreak: WorkoutStreak) => void;
}

const UpdateWorkoutStreakDialog: React.FC<UpdateWorkoutStreakDialogProps> = ({ open, workoutStreak, onClose, onConfirm }) => {
    const [current, setCurrent] = React.useState(workoutStreak.current);
    const [longest, setLongest] = React.useState(workoutStreak.longest);

    React.useEffect(() => {
        setCurrent(workoutStreak.current);
        setLongest(workoutStreak.longest);
    }, [workoutStreak]);

    const handleConfirm = () => {
        onConfirm({ ...workoutStreak, current, longest });
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Update Workout Streak</DialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" gap={2} sx={{ p: 2 }}>
                    <TextField
                        label="Current Streak"
                        type="number"
                        value={current}
                        onChange={(e) => setCurrent(parseInt(e.target.value))}
                        fullWidth
                    />
                    <TextField
                        label="Longest Streak"
                        type="number"
                        value={longest}
                        onChange={(e) => setLongest(parseInt(e.target.value))}
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} variant="contained" color="primary">
                    OK
                </Button>
                <Button onClick={onClose} variant="outlined" color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateWorkoutStreakDialog;

import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ChallangeWorkoutDetails } from '../../../api/users.interface';

interface ComponentProps {
    challengeWorkoutDetails: ChallangeWorkoutDetails;
}

type Props = ComponentProps;

class ChallengeWorkoutDetailsComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderWorkoutDetail(key: string, detail: { createdAt: number; name: string; validPurchase: boolean }) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={detail.name}
                        subheader={`Created at: ${this.formatDate(detail.createdAt)}`}
                        avatar={detail.validPurchase ? <CheckCircleIcon color="primary" /> : <CancelIcon color="error" />}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            {detail.validPurchase ? "Valid Purchase" : "Invalid Purchase"}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { challengeWorkoutDetails } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(challengeWorkoutDetails).map(([key, detail]) => this.renderWorkoutDetail(key, detail))}
                </Grid>
            </Box>
        );
    }
}

export default ChallengeWorkoutDetailsComponent;

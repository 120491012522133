import { GetStateFunction, DispatchFunction } from "../reducers";
import { addAdmin as addAminApi, removeAdmin as removeAdminApi, addAdminManager as addAdminManagerApi, removeAdminManager as removeAdminManagerApi } from "../api/admins";
import { addError, addSuccess } from "./notifications";
import { USERS_LOADING, USERS_UPDATE } from "./types";


export const addAdminManager = (email: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().users;
    try {
        dispatch({ type: USERS_UPDATE, payload: { user: { ...user, manager: true } } });
        await addAdminManagerApi(email);
        dispatch(addSuccess('Admin manager added successfully.'));
        
    } catch (e: any) {
        dispatch({ type: USERS_UPDATE, payload: { user } });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while loading the user. Please try again.'));
        }
    }
}

export const removeAdminManager = (email: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().users;
    try {
        dispatch({ type: USERS_UPDATE, payload: { user: { ...user, manager: false } } });
        await removeAdminManagerApi(email);
        dispatch(addSuccess('Admin manager permission removed successfully.'));
        
    } catch (e: any) {
        dispatch({ type: USERS_UPDATE, payload: { user } });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while loading the user. Please try again.'));
        }
    }
}



export const addAdmin = (email: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().users;
    try {
        dispatch({ type: USERS_UPDATE, payload: { user: { ...user, admin: true } } });

        await addAminApi(email);
        dispatch(addSuccess('Admin manager permission added successfully.'));
        
    } catch (e: any) {
        dispatch({ type: USERS_UPDATE, payload: { user } });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while loading the user. Please try again.'));
        }
        
    }
}

export const removeAdmin = (email: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().users;
    try {
        dispatch({ type: USERS_UPDATE, payload: { user: { ...user, admin: false } } });

        await removeAdminApi(email);
        dispatch(addSuccess('Admin permission removed successfully.'));
        
    } catch (e: any) {
        dispatch({ type: USERS_UPDATE, payload: { user } });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while loading the user. Please try again.'));
        }
    }
}
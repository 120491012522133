import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { CompletedBonusChallenge, CompletedBonusChallenges } from '../../../api/users.interface';

interface ComponentProps {
    completedBonusChallenges: CompletedBonusChallenges;
}

type Props = ComponentProps;

class CompletedBonusChallengesComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderChallenge(key: string, challenge: CompletedBonusChallenge) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={challenge.title}
                        subheader={`Completed On: ${this.formatDate(challenge.createdAt)}`}
                    />
                    <CardMedia
                        component="img"
                        height="140"
                        image={challenge.featureImageUrl}
                        alt={challenge.title}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            {challenge.description}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary">
                            Exercises: {challenge.exercisesInCircuit}
                        </Typography> */}
                        <Typography variant="body2" color="textSecondary">
                            Time: {challenge.time}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary">
                            Tags: {challenge.tags.join(', ')}
                        </Typography> */}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { completedBonusChallenges } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(completedBonusChallenges).map(([key, challenge]) => this.renderChallenge(key, challenge))}
                </Grid>
            </Box>
        );
    }
}

export default CompletedBonusChallengesComponent;

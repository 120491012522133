import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, SvgIcon } from '@mui/material';
import { WaterBottleLog, WaterBottleLogs } from '../../../api/users.interface';

// Define a custom water bottle icon
const WaterBottleIcon = (props: any) => (
    <SvgIcon {...props} viewBox="0 0 101.34 265.88">
    <defs>
    </defs>
    <title>Asset 39</title>
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <path className="cls-1"
                d="M39,265.38c-3.26,0-6.24-.05-9.11-.14-1.12,0-2.32,0-3.55,0-5.11,0-10.9,0-15-2.31C4.27,259,.67,250.66.67,238.18v-188C-.65,39,5.75,32.63,20.24,30.68c.51-.07,1-.14.21-17.4C20.26,9.19,20,4.18,24,2,26.71.56,30.05.55,33,.55L66.62.5a29.13,29.13,0,0,1,8,.78,10.06,10.06,0,0,1,6.47,4.87c.6,1.21.07,11.9-.21,17.64-.33,6.81-.33,6.81.24,6.89,14.48,2,20.88,8.31,19.56,19.44V238.18c.45,8.81-1.1,15.28-4.75,19.81-4.49,5.58-10.76,6.72-18.8,6.72-6.38,0-12.83.17-19.07.33S45.36,265.38,39,265.38Z" />
            <path
                d="M66.62,1a28.92,28.92,0,0,1,7.86.76,9.59,9.59,0,0,1,6.15,4.6c.53,1.32,0,12.19-.26,17.41C80,31,80,31,81,31.17c14.15,1.91,20.41,8.09,19.14,18.9V238.23c.45,8.66-1.07,15-4.64,19.45-4.36,5.42-10.52,6.53-18.46,6.53-6.35,0-12.8.17-19,.33s-12.67.34-19,.34c-3.26,0-6.23-.05-9.09-.14-1.14,0-2.32,0-3.57,0-5.05,0-10.77,0-14.77-2.25-6.92-3.83-10.43-12-10.43-24.27V50.07c-1.27-10.81,5-17,19.14-18.9,1.05-.14,1.21-.16,1-8.84-.1-4-.28-8.16-.32-9.07v-.07c-.19-4.09-.41-8.73,3.27-10.71C26.84,1.06,30.11,1.05,33,1.05L66.06,1h.56m0-1h-.56L33,.05c-3.16,0-6.47,0-9.25,1.55-4.25,2.3-4,7.56-3.79,11.71.08,1.69.71,16.81.22,16.87-14,1.89-21.4,8.12-20,20v188c0,9.45,2,20.19,10.94,25.14,5.23,2.89,13,2.24,18.8,2.42,3,.1,6.08.14,9.12.14,12.67,0,25.37-.67,38-.67h0c7.36,0,14.32-.84,19.2-6.91,4.48-5.56,5.21-13.24,4.86-20.12v-188c1.4-11.88-6-18.1-20-20-.31,0,1.37-22.22.36-24.25A10.58,10.58,0,0,0,74.75.8,29.52,29.52,0,0,0,66.62,0Z" />
            <line className="cls-2" x1="38.67" y1="73.68" x2="89.67" y2="73.68" />
            <line className="cls-2" x1="39.17" y1="106.18" x2="90.17" y2="106.18" />
            <line className="cls-2" x1="39.17" y1="143.18" x2="90.17" y2="143.18" />
            <line className="cls-2" x1="39.17" y1="177.18" x2="90.17" y2="177.18" />
            <line className="cls-2" x1="39.17" y1="207.18" x2="90.17" y2="207.18" />
            <line className="cls-2" x1="39.17" y1="242.18" x2="90.17" y2="242.18" />
        </g>
    </g>
    </SvgIcon>
);

interface ComponentProps {
    waterBottleLogs: WaterBottleLogs;
}

type Props = ComponentProps;

class WaterBottleLogsComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderWaterBottleLog(key: string, log: WaterBottleLog) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title="Water Bottle Log"
                        subheader={`Logged at: ${this.formatDate(log.createdAt)}`}
                    />
                    <CardContent>
                        {!log.count && <Typography variant="body2" color="textSecondary">No water bottles logged</Typography>}
                        <Box display="flex" alignItems="center">
                            {[...Array(log.count)].map((_, index) => (
                                <WaterBottleIcon key={index} color="primary" fontSize="large" />
                            ))}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { waterBottleLogs } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(waterBottleLogs).map(([key, log]) => this.renderWaterBottleLog(key, log))}
                </Grid>
            </Box>
        );
    }
}

export default WaterBottleLogsComponent;

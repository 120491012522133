import * as React from 'react';
import { Box, Typography, Button, Card, CardContent, TextField, CircularProgress } from '@mui/material';
import { ConfirmationDialog } from '../../../components/common/Modal';

interface UpdateTrialPeriodComponentProps {
    loadingTrialPeriod?: boolean;
    currentTrialPeriod: number;
    updateTrialPeriod: (newTrialPeriod: number) => void;
}

const UpdateTrialPeriodComponent: React.FC<UpdateTrialPeriodComponentProps> = ({ currentTrialPeriod, loadingTrialPeriod, updateTrialPeriod }) => {
    const [trialPeriod, setTrialPeriod] = React.useState(currentTrialPeriod);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

    React.useEffect(() => {
        setTrialPeriod(currentTrialPeriod);
    }, [currentTrialPeriod]);

    const handleConfirmUpdate = React.useCallback(() => {
        setIsConfirmDialogOpen(false);
        updateTrialPeriod(trialPeriod);
    }, [trialPeriod]);

    const handleCancelUpdate = React.useCallback(() => {
        setTrialPeriod(currentTrialPeriod);
        setIsConfirmDialogOpen(false);
    }, [currentTrialPeriod]);

    const handleUpdateClick = React.useCallback(() => {
        setIsConfirmDialogOpen(true);
    }, []);

    const onTrialPeriodChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTrialPeriod(parseInt(e.target.value));
    }, []);

    return (
        <Card sx={{ maxWidth: 600, mt: 5 }}>
            <CardContent>
                <Typography variant="h5">Current Trial Period: {currentTrialPeriod} days</Typography>
                {loadingTrialPeriod && (
                       <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
                            <CircularProgress />
                        </Box>
                )}
                {!loadingTrialPeriod && (
                    <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
                        <TextField
                            label="Update Trial Period (days)"
                            type="number"
                            value={trialPeriod}
                            onChange={onTrialPeriodChange}
                            fullWidth
                        />
                        <Button variant="contained" color="primary" onClick={handleUpdateClick}>
                            Update Trial Period
                        </Button>
                    </Box>
                )}
            </CardContent>
            <ConfirmationDialog
                id="confirm-update-trial-period"
                title="Confirm Update"
                visible={isConfirmDialogOpen}
                onClose={handleCancelUpdate}
                onConfirmed={handleConfirmUpdate}
            >
                <Typography>Are you sure you want to update the trial period to {trialPeriod} days?</Typography>
            </ConfirmationDialog>
        </Card>
    );
};

export default UpdateTrialPeriodComponent;

import { Component } from 'react';
import { Card, CardContent, Avatar, Typography, Box, Button } from '@mui/material';
import { UserDetails } from '../../api/users.interface';
import { WithRouterProps, withRouter } from '../../common/hooks/withParams';


interface ComponentProps extends WithRouterProps {
    item: UserDetails;
}

class UserDetailsCard extends Component<ComponentProps> {
    handleViewDetails = () => {
        const { item, navigate } = this.props;
        navigate(`/users/${item.uid}`);
    };

    render() {
        const { item } = this.props;
        return (
            <Card style={{ maxWidth: '50rem' }}>
                <CardContent>
                    <Box display="flex" alignItems="center" marginBottom={2}>
                        <Avatar src={item.avatar} alt={item.username} />
                        <Box marginLeft={2}>
                            <Typography variant="h5">{item.username}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {item.name} {item.lastname}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="body1">Email: {item.email}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        UID: {item.uid}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Admin Manager: {item.manager ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Admin: {item.admin ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Created At: {new Date(item.createdAt).toLocaleDateString()}
                    </Typography>
                    <Box marginTop={2} display="flex" justifyContent="flex-end">
                        <Button variant="contained" color="primary" onClick={this.handleViewDetails}>
                            View Details
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}

export default withRouter(UserDetailsCard);


import { PROCESSING_PROGRAM, MISC_UPDATE, LOADING_TRIAL_PERIOD } from "../actions/types";

export interface MiscState {
    loadingTrialPeriod?: boolean;
    trialPeriod?: number;
    processingProgram: boolean;
}

const InitialState: MiscState = {
    loadingTrialPeriod: false,
    processingProgram: false,
};

export const MiscReducer = (state: MiscState = InitialState, action: any) => {
    switch (action.type) {
        case LOADING_TRIAL_PERIOD:
            return {
                ...state,
                loadingTrialPeriod: action.payload,
            };
        case MISC_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        case PROCESSING_PROGRAM:
            return {
                ...state,
                processingProgram: action.payload,
            };
    }
    return state;
}

export default MiscReducer;
export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED';

export const AUTHENTICATING = 'AUTHENTICATING';
export const AUTH_UPDATE = 'AUTH_UPDATE';
export const USER_UPDATE = 'USER_UPDATE';

export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_LOADING = 'USERS_LOADING';

export const LOADING_TRIAL_PERIOD = 'LOADING_TRIAL_PERIOD';
export const TRIAL_PERIOD_UPDATE = 'TRIAL_PERIOD_UPDATE';
export const MISC_UPDATE = 'MISC_UPDATE';

export const PROCESSING_PROGRAM = 'PROCESSING_PROGRAM';
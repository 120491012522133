import axios from 'axios'

import { AdminDetails, AuthenticationResponse } from './auth.types'
import { ApiError } from '../common/errors'
const AUTH_ENDPOINT = `/api/auth/login`
const USER_ENDPOINT = `/api/auth/details`


export const authenticate = async(email: string, password: string): Promise<AuthenticationResponse> => {
    const payload = {
        email,
        password,
    }
    try {
        const { data } = await axios.post(AUTH_ENDPOINT, payload)

        return data as AuthenticationResponse
    } catch(e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }

        throw e
    }
}

export const userDetails = async(): Promise<AdminDetails> => {
    try {
        const { data } = await axios.get(USER_ENDPOINT)

        return data as AdminDetails
    } catch(e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }

        throw e
    }
}

export const hasScope = (scope: string, query: string): boolean => {
    return scope?.includes(query);
}
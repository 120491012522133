import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, CardMedia, Chip } from '@mui/material';
import { CompletedWorkout, CompletedWorkouts } from '../../../api/users.interface';

interface ComponentProps {
    completedWorkouts: CompletedWorkouts;
}

type Props = ComponentProps;

class CompletedWorkoutsComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderWorkout(key: string, workout: CompletedWorkout) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={workout.description}
                        subheader={`Completed On: ${this.formatDate(workout.createdAt)}`}
                    />
                    <CardMedia
                        component="img"
                        height="140"
                        image={workout.imageUrl}
                        alt={workout.description}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Level: {workout.level}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Primary Type: {workout.primaryType}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Time: {workout.time}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Day: {workout.day}, Week: {workout.week}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {workout.isToday ? "Completed Today" : ""}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { completedWorkouts } = this.props;
        const completedWorkoutsOrderedByDate = Object.entries(completedWorkouts).sort((a, b) => a[1].createdAt === b[1].createdAt ? 0 : a[1].createdAt ? -1 : 1);
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {completedWorkoutsOrderedByDate.map(([key, workout]) => this.renderWorkout(key, workout))}
                </Grid>
            </Box>
        );
    }
}

export default CompletedWorkoutsComponent;

import { USERS_UPDATE, USERS_LOADING } from "../actions/types";
import { UserDetails, UserProfile } from "../api/users.interface";

export interface UsersState {
    loadingUsers?: boolean;
    searchItem?: UserDetails;
    user?: UserProfile;
}

const InitialState: UsersState = {
    loadingUsers: false,
};

export const UsersReducer = (state: UsersState = InitialState, action: any) => {
    switch (action.type) {
        case USERS_LOADING:
            return {
                ...state,
                loadingUsers: action.payload,
            };
        case USERS_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
    }
    return state;
}

export default UsersReducer;
import React, { Component } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Routes, Route, Link } from 'react-router-dom';
import Template from './TemplateComponent';
import UserSearch from '../users/UserSearch';
import UserProfilePage from '../users/UserProfilePage';
import Misc from '../misc/Misc';


const drawerWidth = 240;

class AdminPageComponent extends Component {
  render() {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            borderRight: '1px solid #e0e0e0',
            height: '100vh',
          }}
        >
          <Typography variant="h6" noWrap component="div" sx={{ p: 2 }}>
            Admin Dashboard
          </Typography>
          <List>
            {['Users', 'Misc'].map((text, index) => (
              <ListItem button key={text} component={Link} to={`/${text.toLowerCase()}`} sx={{ borderTop: index === 0 ? '1px solid #e0e0e0' : undefined,  borderBottom: '1px solid #e0e0e0', pt: '1rem', pb: '1rem' }}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Routes>
            <Route path="/" element={<UserSearch />} />
            <Route path="/users" element={<UserSearch />} />
            <Route path="/users/:uid" element={<UserProfilePage />} />
            <Route path="/misc" element={<Misc />} />
          </Routes>
        </Box>
      </Box>
    );
  }
}

export default AdminPageComponent;

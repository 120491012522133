import axios from 'axios';
import { UserDetails, UserProfile, UserProfileStatistics, WorkoutStreak } from './users.interface';
import { ApiError } from '../common/errors';

export const ADMINS_URL = '/api/admins';
export const ADMINS_MANAGERS_URL = '/api/admins/managers';

export const addAdminManager = async (email: string): Promise<boolean> => {
    try {
        await axios.post(`${ADMINS_MANAGERS_URL}/add`, { email });

        return true;
    } catch (e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};

export const removeAdminManager = async (email: string): Promise<boolean> => {
    try {
        await axios.post(`${ADMINS_MANAGERS_URL}/remove`, { email });

        return true;
    } catch (e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};

export const addAdmin = async (email: string): Promise<boolean> => {
    try {
        await axios.post(`${ADMINS_URL}/add`, { email });

        return true;
    } catch (e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};

export const removeAdmin = async (email: string): Promise<boolean> => {
    try {
        await axios.post(`${ADMINS_URL}/remove`, { email });

        return true;
    } catch (e: any) {
        if (e.response?.status === 404) {
            throw new ApiError('User not found');
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};
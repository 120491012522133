import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, LinearProgress, Chip, Button } from '@mui/material';
import { Goals, WorkoutStreak } from '../../../api/users.interface';
import UpdateWorkoutStreakDialog from './UpdateWorkoutStreakDialog';  // Update the import path as needed

interface ComponentProps {
    goals: Goals;
    updateWorkoutStreak: (workoutStreak: WorkoutStreak) => void;
}

interface ComponentState {
    isDialogOpen: boolean;
    workoutStreak: WorkoutStreak;
}

type Props = ComponentProps;

class GoalsComponent extends Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isDialogOpen: false,
            workoutStreak: { ...props.goals.workoutStreak }
        };
    }

    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    handleDialogOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ isDialogOpen: false });
    };

    handleUpdateWorkoutStreak = (updatedStreak: WorkoutStreak) => {
        this.props.updateWorkoutStreak(updatedStreak);
        this.setState({ workoutStreak: updatedStreak, isDialogOpen: false });
    };

    renderDailyWaterIntake() {
        const { dailyWaterIntake } = this.props.goals;
        const progress = (dailyWaterIntake.current / dailyWaterIntake.target) * 100;

        return (
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader
                        title="Daily Water Intake"
                        subheader={`Last Reset: ${this.formatDate(dailyWaterIntake.lastReset)}`}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Target: {dailyWaterIntake.target} ml
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Current: {dailyWaterIntake.current} ml
                        </Typography>
                        <LinearProgress variant="determinate" value={progress} />
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    renderWeeklyWorkout() {
        const { weeklyWorkout } = this.props.goals;
        const progress = (weeklyWorkout.current / weeklyWorkout.target) * 100;

        return (
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader
                        title="Weekly Workout"
                        subheader={`Last Reset: ${this.formatDate(weeklyWorkout.lastReset)}`}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Target: {weeklyWorkout.target} workouts
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Current: {weeklyWorkout.current} workouts
                        </Typography>
                        <LinearProgress variant="determinate" value={progress} />
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    renderWeightGoal() {
        const { weight } = this.props.goals;

        return (
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Target: {weight.target} kg
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Current: {weight.current} kg
                        </Typography>
                        {weight.goalAchieved ? (
                            <Chip label="Goal Achieved" color="primary" />
                        ) : (
                            <Chip label="Goal Not Achieved" color="secondary" />
                        )}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    renderWorkoutStreak() {
        const { workoutStreak } = this.props.goals;

        return (
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader
                        title="Workout Streak"
                        subheader={`Logged At: ${this.formatDate(workoutStreak.loggedAt)}`}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Current Streak: {workoutStreak.current} days
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Longest Streak: {workoutStreak.longest} days
                        </Typography>
                        <Button sx={{ mt: '1rem' }} variant="contained" color="primary" onClick={this.handleDialogOpen}>
                            Update Streak
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { isDialogOpen, workoutStreak } = this.state;

        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {this.renderDailyWaterIntake()}
                    {this.renderWeeklyWorkout()}
                    {this.renderWeightGoal()}
                    {this.renderWorkoutStreak()}
                </Grid>
                <UpdateWorkoutStreakDialog
                    open={isDialogOpen}
                    workoutStreak={workoutStreak}
                    onClose={this.handleDialogClose}
                    onConfirm={this.handleUpdateWorkoutStreak}
                />
            </Box>
        );
    }
}

export default GoalsComponent;

import { GetStateFunction, DispatchFunction } from "../reducers";
import { getUserDetailsByEmailOrUID, getUserObjectByUid, updateWorkoutStreak as updateWorkoutStreakApi, updateUser as updateUserApi } from "../api/users";
import { addError, addSuccess } from "./notifications";
import { USERS_LOADING, USERS_UPDATE } from "./types";
import { UserDetails, WorkoutStreak } from "../api/users.interface";


export const searchUserByEmailorUID = (search: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: USERS_UPDATE, payload: { searchItem: undefined, loadingUsers: true } });
        const user = await getUserDetailsByEmailOrUID(search);

        dispatch({ type: USERS_UPDATE, payload: { searchItem: user, loadingUsers: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while searching for the user.'));
        }
        
        dispatch({ type: USERS_LOADING, payload: false });
    }
}

export const loadUserByUID = (uid: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    dispatch({ type: USERS_LOADING, payload: true });
    try {
        const { user, stats } = await getUserObjectByUid(uid);

        dispatch({ type: USERS_UPDATE, payload: { user, stats, loadingUsers: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while loading the user. Please try again.'));
        }
        
        dispatch({ type: USERS_LOADING, payload: false });
    }
}

export const updateWorkoutStreak = (uid: string, workoutStreak: WorkoutStreak) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().users;
    if (!user) {
        dispatch(addError('User not found.'));
        return
    }
    const userUpdate = {
        ...user,
        goals: {
            ...user.goals,
            workoutStreak: {
                ...workoutStreak,
                loggedAt: Date.now()
            }
        }
    }
    dispatch({ type: USERS_UPDATE, payload: { user: userUpdate } });
    try {
        await updateWorkoutStreakApi(uid, workoutStreak);
        dispatch(addSuccess('Workout streak updated successfully.'))
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while updating the workout streak. Please try again.'));
        }
        dispatch({ type: USERS_UPDATE, payload: { user } });
    }
}

export const updateUser = (uid: string, update: Partial<UserDetails>) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().users;
    if (!user) {
        dispatch(addError('User not found.'));
        return
    }
    const userUpdate = {
        ...user,
        ...update,
    }
    dispatch({ type: USERS_UPDATE, payload: { user: userUpdate } });
    try {
        await updateUserApi(uid, update);
        dispatch(addSuccess('User updated successfully.'))
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while updating this user. Please try again.'));
        }
        dispatch({ type: USERS_UPDATE, payload: { user } });
    }
}
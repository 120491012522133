import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader } from '@mui/material';
import { SelfCareLog, SelfCareLogs } from '../../../api/users.interface';

interface ComponentProps {
    selfCareLogs: SelfCareLogs;
}

type Props = ComponentProps;

class SelfCareLogsComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderSelfCareLog(key: string, log: SelfCareLog) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title="Self Care Log"
                        subheader={`Logged at: ${this.formatDate(log.createdAt)}`}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Activities: {log.activities}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { selfCareLogs } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(selfCareLogs).map(([key, log]) => this.renderSelfCareLog(key, log))}
                </Grid>
            </Box>
        );
    }
}

export default SelfCareLogsComponent;

import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, SvgIcon } from '@mui/material';
import { Weight, Weights } from '../../../api/users.interface';

// Define a custom weight icon
const WeightIcon = (props: any) => (
    <SvgIcon {...props}>
        <path d="M12 2C8.13 2 5 5.13 5 9c0 2.38 1.19 4.47 3 5.74V20c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-4h2v4c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-5.26c1.81-1.27 3-3.36 3-5.74 0-3.87-3.13-7-7-7zm0 2c2.76 0 5 2.24 5 5 0 2.2-1.52 4.04-3.58 4.72L12 20h-1v-4H9v4H8v-5.28C5.52 13.04 4 11.2 4 9c0-2.76 2.24-5 5-5h3zm-.5 3C10.67 7 10 7.67 10 8.5S10.67 10 11.5 10 13 9.33 13 8.5 12.33 7 11.5 7zM13 12h-2v2h2v-2z" />
    </SvgIcon>
);

interface ComponentProps {
    weights: Weights;
}

type Props = ComponentProps;

class WeightsComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderWeightLog(key: string, log: Weight) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title="Weight Log"
                        subheader={`Logged at: ${this.formatDate(log.createdAt)}`}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Weight: {log.weight} kg
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { weights } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(weights).map(([key, log]) => this.renderWeightLog(key, log))}
                </Grid>
            </Box>
        );
    }
}

export default WeightsComponent;

import { Autocomplete, FormControl, InputLabel, MenuItem, SxProps, TextField, Theme } from "@mui/material"
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import React from "react";

export interface SelectOption<ValueType = any, ItemType = any> {
    label: string;
    value: ValueType;
    item?: ItemType;
}

export interface SelectProps<ValueType = any, ItemType = any> {
    label: string;
    items: SelectOption<ValueType, ItemType>[];
    value?: SelectOption<ValueType, ItemType> | null;
    sx?: SxProps<Theme>;
    disableSearch?: boolean;
    clearable?: boolean;
    onItemSelected?: (item: SelectOption<ValueType, ItemType>) => void;
}

export const Select: React.FC<SelectProps> = ({ sx, label, value, items, disableSearch, clearable, onItemSelected }) => {
    const onHandleChange = React.useCallback((event: SelectChangeEvent) => {
        const { value } = event.target;
        const item = items.find((item) => item.value === value);
        if (item) {
            onItemSelected?.(item);
        }
    }, [items, onItemSelected]);

    const onHandleAutocompleteChange = React.useCallback((_: any, item: any) => {
        onItemSelected?.(item);
    }, [items, onItemSelected]);
    return (
        <>
        {!disableSearch &&
            <Autocomplete
                disablePortal
                id={`${label}-select`}
                options={items}
                sx={{ ...(sx || {})}}
                value={value as any}
                onChange={onHandleAutocompleteChange}
                disableClearable={!clearable}
                renderInput={(params) => <TextField {...params} label={label} />}
            />
        }
        {disableSearch && 
            <FormControl sx={{ ...(sx || {})}} fullWidth>
                <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
                <MUISelect
                    labelId={`${label}-select-label`}
                    id={`${label}-select`}
                    value={value?.value ?? null}
                    label={label}
                    onChange={onHandleChange}
                >
                    {items.map((item) => {
                        return (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        )})
                    }
                </MUISelect>
            </FormControl>
        }
        </>
    )
}
/*eslint import/namespace: [2, { allowComputed: true }]*/
import React, { useEffect } from 'react';
import axios from 'axios'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { LoginComponent } from './components/auth/Login';
import { Header } from './components/nav/Header';
import { Box } from '@mui/material';
import { RequiresAuthentication } from './components/common/RequiresAuthentication';
import NotificationBar from './components/common/NotificationBar';
import NotFound from './components/common/Errors/NotFound';
import AdminPageComponent from './components/admin/AdminPage';
import { connect } from 'react-redux';
import { ReduxState } from './reducers';
import { initialize } from './actions/auth';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const AppComponent: React.FunctionComponent<{ initialized: boolean, initialize: () => void }> = ({ initialized, initialize }) => {
  useEffect(() => {
    if (!initialized) {
      initialize()
    }
    
  }, [initialized, initialize]);
  return (
      <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
              <NotificationBar />
              <Header />
              <Box>
                <Routes>
                  <Route path="/login" element={<LoginComponent />} />
                  {<Route path="/" element={<RequiresAuthentication><AdminPageComponent /></RequiresAuthentication>} />}
                  {<Route path="/*" element={<RequiresAuthentication><AdminPageComponent /></RequiresAuthentication>} />}
                  <Route path='*' element={<NotFound />} />
                </Routes>
              </Box>
            </Box>
      </Router>
  );
};

const mapStateToProps = ({ auth }: ReduxState) => {
  const { initialized, authenticated } = auth;
  
  return {
    initialized,
    authenticated
  };
};

export const App = connect(mapStateToProps, { initialize })(AppComponent);
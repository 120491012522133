import * as React from 'react';
import { Box, Typography, Button, Card, CardContent, TextField, CircularProgress, FormLabel } from '@mui/material';
import { ConfirmationDialog } from '../../common/Modal';
import { WORKOUT_LEVEL_OPTIONS, WorkoutLevel } from '../../../api/workouts.interface';
import { Select, SelectOption } from '../../../components/common/Select';

interface UpdateTrialPeriodComponentProps {
    processingProgram: boolean;
    processProgram: (level: WorkoutLevel) => void;
}

const ProcessPrograms: React.FC<UpdateTrialPeriodComponentProps> = ({ processProgram, processingProgram }) => {
    const [level, setLevel] = React.useState<SelectOption<WorkoutLevel | undefined>>({ label: '', value: undefined });

    const onProcessProgram = React.useCallback(() => {
        if (!level.value) {
            return;
        }
        processProgram(level.value);
    }, [level]);


    return (
        <Card sx={{ maxWidth: 600, mt: 5 }}>
            <CardContent>
                <Typography variant="h5">Process Programs</Typography>
                {processingProgram && (
                       <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
                            <CircularProgress />
                        </Box>
                )}
                {!processingProgram && (
                    <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
                        <Select 
                            label="Level"
                            items={WORKOUT_LEVEL_OPTIONS} value={level}
                            onItemSelected={setLevel}
                        />
                        <Button variant="contained" color="primary" onClick={onProcessProgram}>
                            Process
                        </Button>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default ProcessPrograms;

import axios from 'axios';
import { UserDetails, UserProfile, UserProfileStatistics, WorkoutStreak } from './users.interface';
import { ApiError } from '../common/errors';
import { WorkoutLevel } from './workouts.interface';

export const PROCESS_PROGRAM_ENDPOINT = '/api/workouts/process-program';

export const processProgram = async (level: WorkoutLevel): Promise<boolean> => {
    try {
        const { data } = await axios.post(PROCESS_PROGRAM_ENDPOINT, { level });
        const { success } = data;
        if (!success) {
            throw new ApiError('Failed to process program');
        }

        return true;
    } catch (e: any) {
        if (e instanceof ApiError) {
            throw e;
        }
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};

import axios from 'axios';
import { UserDetails, UserProfile, UserProfileStatistics, WorkoutStreak } from './users.interface';
import { ApiError } from '../common/errors';

export const TRIAL_PERIOD_ENDPOINT = '/api/misc/trial-period';

export const getCurrentTrialPeriod = async (): Promise<number> => {
    try {
        const { data } = await axios.get(TRIAL_PERIOD_ENDPOINT);
        const { item } = data;

        return Number(item);
    } catch (e: any) {
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};


export const updateTrialPeriod = async (trialPeriod: number): Promise<void> => {
    try {
        await axios.post(TRIAL_PERIOD_ENDPOINT, { trialPeriod });
    } catch (e: any) {
        if (e.response?.status === 400) {
            throw new ApiError(e.response?.data?.message);
        }
        throw new Error(e.response?.data?.message || e.message);
    }
};
import { Component } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Box } from '@mui/material';
import { ReduxState } from '../../reducers';
import UpdateTrialPeriodComponent from './TrialPeriod/UpdateTrialPeriodComponent';
import { getCurrentTrialPeriod, updateTrialPeriod } from '../../actions/misc';
import { processProgram } from '../../actions/workouts';
import { WorkoutLevel } from '../../api/workouts.interface';
import ProcessPrograms from './Programs/ProcessPrograms';

export interface ComponentState {}

interface ComponentProps {}

interface ReduxProps {
    loadingTrialPeriod?: boolean;
    trialPeriod?: number;
    processingProgram: boolean;
}

interface ReduxActionProps {
    getCurrentTrialPeriod: () => void;
    updateTrialPeriod: (trialPeriod: number) => void;
    processProgram: (level: WorkoutLevel) => void;
}

type Props = ComponentProps & ReduxProps & ReduxActionProps;

class MiscComponent extends Component<Props, ComponentState> {
    state: ComponentState = {};

    componentDidMount() {
        this.props.getCurrentTrialPeriod();
    }

    render() {
        const { processingProgram } = this.props;
        return (
            <Box>
                <UpdateTrialPeriodComponent loadingTrialPeriod={this.props.loadingTrialPeriod} currentTrialPeriod={this.props.trialPeriod ?? 0} updateTrialPeriod={this.props.updateTrialPeriod} />
                <ProcessPrograms processingProgram={processingProgram} processProgram={this.props.processProgram} />
            </Box>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ReduxState> = ({ misc }: ReduxState) => {
    const { loadingTrialPeriod, trialPeriod, processingProgram } = misc;
    
    return {
        loadingTrialPeriod,
        trialPeriod,
        processingProgram,

    };
};

export default connect<ReduxProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { getCurrentTrialPeriod, updateTrialPeriod, processProgram })(MiscComponent);

import { Component, ChangeEvent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { ReduxState } from '../../reducers';
import { searchUserByEmailorUID } from '../../actions/users';
import { UserDetails } from '../../api/users.interface';
import UserDetailsCard from './UserDetailsCard';

export interface ComponentState {
    searchQuery: string;
}

interface ComponentProps {}

interface ReduxProps {
    loadingUsers?: boolean;
    searchItem?: UserDetails;
}

interface ReduxActionProps {
    searchUserByEmailorUID: (query: string) => void;
}

type Props = ComponentProps & ReduxProps & ReduxActionProps;

class UserSearch extends Component<Props, ComponentState> {
    state: ComponentState = {
        searchQuery: '',
    };

    handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchQuery: event.target.value });
    };

    handleSearch = () => {
        const { searchQuery } = this.state;
        this.props.searchUserByEmailorUID(searchQuery);
    };

    render() {
        const { searchQuery } = this.state;
        const { loadingUsers, searchItem } = this.props;
        return (
            <Box>
                <Typography variant="h5" sx={{ mb: '1rem' }}>Search for a user</Typography>
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Email or UID"
                        value={searchQuery}
                        onChange={this.handleInputChange}
                        variant="outlined"
                        size="small"
                        style={{ marginRight: '8px' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSearch}
                    >
                        Search
                    </Button>
                </Box>
                {loadingUsers && (
                    <Box sx={{ margin: '10rem '}}>
                        <CircularProgress />
                    </Box>
                )}
                {searchItem && (
                    <Box>
                        <UserDetailsCard item={searchItem} />
                    </Box>
                )}
            </Box>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ReduxState> = ({ users }: ReduxState) => {
    const { loadingUsers, searchItem } = users;
    return {
        loadingUsers,
        searchItem
    };
};

export default connect<ReduxProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { searchUserByEmailorUID })(UserSearch);

import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { SpringSlimDownChallenge, SpringSlimDownChallenges } from '../../../api/users.interface';

interface ComponentProps {
    springSlimDownChallenges: SpringSlimDownChallenges;
}

type Props = ComponentProps;

class SpringSlimDownChallengesComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderChallenge(key: string, challenge: SpringSlimDownChallenge) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Spring Slim Down Challenge
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {`Logged On: ${this.formatDate(challenge.createdAt)}`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { springSlimDownChallenges } = this.props;
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {Object.entries(springSlimDownChallenges).map(([key, challenge]) => this.renderChallenge(key, challenge))}
                </Grid>
            </Box>
        );
    }
}

export default SpringSlimDownChallengesComponent;

import { SelectOption } from "../components/common/Select";

export enum WorkoutLevel {
    Beginner = 'Beginner',
    Intermediate = 'Intermediate',
    Advanced = 'Advanced',
}

export const WORKOUT_LEVELS = Object.values(WorkoutLevel);

export const WORKOUT_LEVEL_OPTIONS: SelectOption<WorkoutLevel>[] = WORKOUT_LEVELS.map((level) => ({
    label: level,
    value: level,
}));
  
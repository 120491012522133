import React, { Component } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ReduxState } from '../../reducers';
import UserDetailsCard from './UserDetailsCard'; // Assuming the path to UserDetailsCard
import { UserDetails, UserProfile, WorkoutStreak } from '../../api/users.interface';
import { loadUserByUID, updateUser, updateWorkoutStreak } from '../../actions/users';
import { withRouter, WithRouterProps } from '../../common/hooks/withParams';
import UserProfileContent from './UserProfileConent';

import { addError } from '../../actions/notifications';
import { addAdmin, addAdminManager, removeAdmin, removeAdminManager } from '../../actions/admins';
export interface ComponentState {}

interface ComponentProps {}

interface ReduxProps {
    loadingUsers?: boolean;
    user?: UserProfile;
    manager?: boolean;
    self?: boolean;
}

interface ReduxActionProps {
    loadUserByUID: (uid: string) => void;
    updateUser: (uid: string, user: Partial<UserDetails>) => void;
    updateWorkoutStreak: (uid: string, updatedStreak: WorkoutStreak) => void;
    addError: (message: string) => void;
    addAdmin: (email: string) => void;
    addAdminManager: (email: string) => void;
    removeAdmin: (email: string) => void;
    removeAdminManager: (email: string) => void;
}

type Props = ComponentProps & ReduxProps & ReduxActionProps & WithRouterProps;

class UserProfilePage extends Component<Props, ComponentState> {
    componentDidMount() {
        const { uid } = this.props.params;
        this.props.loadUserByUID(uid);
    }

    render() {
        const { loadingUsers, user } = this.props;

        if (loadingUsers) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            );
        }

        if (!user) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <Typography variant="h5">User not found</Typography>
                </Box>
            );
        }

        return (
            <Box display="flex" height="100vh">
                <UserProfileContent 
                    item={user}
                    self={this.props.self}
                    manager={this.props.manager}
                    addError={this.props.addError}
                    addAdmin={this.props.addAdmin}
                    addAdminManager={this.props.addAdminManager}
                    removeAdmin={this.props.removeAdmin}
                    removeAdminManager={this.props.removeAdminManager}
                    updateWorkoutStreak={this.props.updateWorkoutStreak}
                    updateUser={this.props.updateUser}
                />
            </Box>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ReduxState> = ({ users, auth }: ReduxState) => {
    const { loadingUsers, user } = users;
    const { user: authUser } = auth;
    
    return {
        loadingUsers,
        user,
        manager: authUser?.manager,
        self: authUser?.email.toLowerCase() === user?.email.toLowerCase(),
    };
};

export default connect<ReduxProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { addError, updateUser, loadUserByUID, updateWorkoutStreak, addAdmin, addAdminManager, removeAdmin, removeAdminManager })(withRouter(UserProfilePage));

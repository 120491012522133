import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, Chip } from '@mui/material';
import { SweatLog, SweatLogs } from '../../../api/users.interface';

interface ComponentProps {
    sweatLogs: SweatLogs;
}

type Props = ComponentProps;

class SweatLogsComponent extends Component<Props> {
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    renderSweatLog(key: string, log: SweatLog) {
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={log.Workout}
                        subheader={`Logged at: ${this.formatDate(log.createdAt)}`}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            Accomplishments: {log.accomplishments}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Bonus Challenge: {log.bonusChallenge}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Distance: {log.distance}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Felt: {log.felt?.join(', ') ?? 'Unknown'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Mood: {log.mood}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Notes: {log.notes}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Self Care: {log.selfCare}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Time: {log.time}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Weight: {log.weight}
                        </Typography>
                        {log.isD10Completed ? (
                            <Chip label="D10 Completed" color="primary" />
                        ) : (
                            <Chip label="D10 Not Completed" color="secondary" />
                        )}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { sweatLogs } = this.props;
        const sweatLogsOrderedByDate = Object.entries(sweatLogs).sort((a, b) => a[1].createdAt === b[1].createdAt ? 0 : a[1].createdAt ? -1 : 1);
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {sweatLogsOrderedByDate.map(([key, log]) => this.renderSweatLog(key, log))}
                </Grid>
            </Box>
        );
    }
}

export default SweatLogsComponent;

import React, { FunctionComponent } from 'react';
import { Avatar, Box, Typography } from '@mui/material';

import { UserDropdown } from './UserDropdown';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
const logo = require('../../assets/logo.png')

const HeaderComponent: FunctionComponent<{ authenticated: boolean }> = ({ authenticated}) => {
    const navigate = useNavigate()

    const onLogoClick = React.useCallback((() => {
        navigate('/')
    }), [navigate]);

    return (
        <Box sx={{ 
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '64px',
            minHeight: '64px',
            zIndex: 10,
            boxShadow: 1,
            backgroundColor: '#ff7793',
        }}>
           <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box sx={{ pl: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box component={'img'} src={logo} alt="Logo" sx={{ height: 40, cursor: 'pointer' }} onClick={onLogoClick} />
                    <Typography sx={{ ml: '1.5rem', color: 'white' }} variant="h5" fontWeight="bold">Admin</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {authenticated && <UserDropdown />}
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = ({ auth }: ReduxState) => {
    const { authenticated } = auth;

    return { authenticated: authenticated };
}

export const Header = connect(mapStateToProps)(HeaderComponent);

import { Component } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Achievements } from '../../../api/users.interface';

interface ComponentProps {
    achievements: Achievements;
}

type Props = ComponentProps;

class AchievementsComponent extends Component<Props> {
    renderAchievement(key: string, achieved: boolean) {
        const title = `${key.charAt(0).toUpperCase()}${key.replace(/([A-Z])/g, ' $1').trim().slice(1)}`.replace('Did ', '');
        
        return (
            <Grid item xs={12} sm={6} md={4} key={key}>
                <Card>
                    <CardHeader
                        title={title}
                        avatar={achieved ? <CheckCircleIcon color="primary" /> : <CancelIcon color="error" />}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            {achieved ? "Achieved" : "Not Achieved"}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    render() {
        const { achievements } = this.props;
        const achievementsOrderedByCompleted = Object.entries(achievements).sort((a, b) => a[1] === b[1] ? 0 : a[1] ? -1 : 1);
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    {achievementsOrderedByCompleted.map(([key, value]) => this.renderAchievement(key, value))}
                </Grid>
            </Box>
        );
    }
}

export default AchievementsComponent;

import { combineReducers } from "redux";
import { AuthReducer, AuthState } from "./AuthReducer";
import NotificationReducer, { NotificationsState } from "./NotificationsReducer";
import UsersReducer, { UsersState } from "./UsersReducer";
import MiscReducer, { MiscState } from "./MiscReducer";

export interface ReduxState {
    auth: AuthState;
    notifications: NotificationsState;
    users: UsersState;
    misc: MiscState;
}

export type GetStateFunction = () => ReduxState;
export type DispatchFunction = (action: { type: string, payload: any }) => void;

export const reducers: any = combineReducers({
    auth: AuthReducer,
    notifications: NotificationReducer,
    users: UsersReducer,
    misc: MiscReducer,
});
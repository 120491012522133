import axios, { AxiosError } from "axios";
import { authenticate, userDetails } from "../api/auth";
import { DispatchFunction, GetStateFunction } from "../reducers";
import { addError, addSuccess } from "./notifications";
import { AUTHENTICATING, AUTH_UPDATE } from "./types";
import moment from 'moment';
import store from "../store";

const AUTH_TOKEN = 'AUTH_TOKEN'
const USER = 'USER'
const AUTH_EXPIRES_AT = 'AUTH_EXPIRES_AT'

export const refreshUserDetails = () => async (dispatch: DispatchFunction) => {
    try {
        const user = await userDetails();
        localStorage.setItem(USER, JSON.stringify(user));
        dispatch({ type: AUTH_UPDATE, payload: { user } });
    } catch(e) {
        console.error(e);
    }

}

export const initialize = () => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    dispatch({ type: AUTHENTICATING, payload: true });
    const { auth } = getState();
    if (auth.initialized) {
        dispatch({ type: AUTHENTICATING, payload: false });
        return;
    }
    const user = localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER) as string) : null;
    const authToken = localStorage.getItem(AUTH_TOKEN);
    const authExpiresAt = localStorage.getItem(AUTH_EXPIRES_AT) ?? '';
    if (!user || !authToken || !authExpiresAt) {
        dispatch({ type: AUTH_UPDATE, payload: { loading: false, authenticated: false, initialized: true } })
        return;
    }
    
    if (moment().isAfter(new Date(Number(authExpiresAt) * 1000))) {
        // dispatch({ type: AUTH_UPDATE, payload: { loading: false, authenticated: false, initialized: true } })
        // return;
    }

    addAccessTokenToAxios(authToken, authExpiresAt);
    dispatch({ type: AUTH_UPDATE, payload: { user, authToken, loading: false, authenticated: true, initialized: true } })
    dispatch(refreshUserDetails() as any);
}


export const addAccessTokenToAxios = (accessToken: string, authExpiresAt: string) => {
    (axios.defaults.headers as any)['x-access-token'] = accessToken;
    axios.interceptors.response.use(
        response => response,
        async (error) => {
            const shouldLogout = moment().isAfter(new Date(Number(authExpiresAt) * 1000)) || [401, 403, 0].includes(error.response.status)
          if (shouldLogout) {
            store.dispatch(addError('Session expired, please login again') as any);
            store.dispatch(logout() as any);
          }
      
          return Promise.reject(error);
        }
      );
}


export const login = (email: string, password: string): any => async (dispatch: DispatchFunction) => {
    dispatch({ type: AUTHENTICATING, payload: true });
    try {
        const { access_token: authToken, expires_at } = await authenticate(email, password);
        addAccessTokenToAxios(authToken, expires_at.toString());
        const user = await userDetails();
        
        localStorage.setItem(AUTH_TOKEN, authToken);
        localStorage.setItem(USER, JSON.stringify(user));
        localStorage.setItem(AUTH_EXPIRES_AT, expires_at.toString());

        dispatch({ type: AUTH_UPDATE, payload: { user, authToken, loading: false, authenticated: true } });
        dispatch(addSuccess('Successfully logged in'));
    } catch(e) {
        if (e instanceof AxiosError) {
            dispatch(addError(e.response?.data?.message || e.message));
        } else {
            dispatch(addError((e as any).message));
        }
    } finally {
        dispatch({ type: AUTHENTICATING, payload: false });
    }
}

export const logout = () => async (dispatch: DispatchFunction) => {
    localStorage.removeItem(USER);
    localStorage.removeItem(AUTH_TOKEN);
    dispatch({ type: AUTH_UPDATE, payload: { user: undefined, authToken: '', authenticated: false } });
}